import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-shipping-on-ozon',
  templateUrl: './shipping-on-ozon.component.html',
  styleUrls: ['./shipping-on-ozon.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ShippingOnOzonPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/marketplejsy',
      linkTitle: 'Доставка до маркетплейсов'
    }, {
      link: '',
          linkTitle: 'Доставка до маркетплейса Озон'
        }]
  }

  description = "Маркетплейс озон  - одна из крупнейших площадок электронной коммерции в России,  которая объединяет огромное количество поставщиков и покупателей. У OZON существует три основных распределительных центра (в Москве и Твери), в которые возможна поставка товаров. Компания «АКРО» осуществляет грузоперевозки в каждый из них, обеспечивая качество данной услуги. Сдача на склад получателя производится нашими штатными экспедиторами, которые в полном объеме представляют ваши интересы. Также наши специалисты полностью контролируют корректность и своевременность поступления товарно-сопроводительных документов. Мы доставляем моно-, микс-паллеты, малогабаритный и крупногабаритный груз от 500 кг до 20 тонн. Забираем товары в Москве, Московской области, отвозим на маркетплейсы по всей России."

  typeActive  =  0;
  typeTitle = 'Выберите количество паллет';
  header = [{
    title: 'Кол-во паллет',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  },{
    title: 'РЦ Тверь',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  },{
    title: 'РЦ Хоругвино',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  },{
    title: 'РЦ Петровское',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  },{
    title: 'РЦ Пушкино',
    class: 'col-12 col-md p-0-xs pr-15-xs'
  }
  // ,{
  //   title: 'Простой более 3-х<br>часов на маркетплейсе',
  //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md p-0-xs'
  // }
  ]

  tariffs = [{
    title: 'до 6 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_6.svg',
      alt: 'Паллета 6',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 1,5 тонн</span> <span class="d-block">До 6 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: 'Под запрос',
        ndsPrice: 'Под запрос',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '9400 ₽',
        ndsPrice: '10800 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '9200 ₽',
        ndsPrice: '10600 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '8700 ₽',
        ndsPrice: '10000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      // ,{
      //   price: '770 ₽/ч',
      //   ndsPrice: '880 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  }, {
    title: '7-8 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_7-8.svg',
      alt: 'Паллета 7-8',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 3 тонн</span> <span class="d-block">7-8 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: 'Под запрос',
        ndsPrice: 'Под запрос',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '10600 ₽',
        ndsPrice: '12200 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '10300 ₽',
        ndsPrice: '11900 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '9800 ₽',
        ndsPrice: '11300 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
        //,
      //   {
      //   price: '22 ₽/км',
      //   ndsPrice: '25 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },
      //   {
      //     price: '1100 ₽/ч',
      //     ndsPrice: '980 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]

  }, {
    title: '9-10 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_9-10.svg',
      alt: 'Паллета 9-10 шт.',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 3 тонн</span><span class="d-block">9-10 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: 'Под запрос',
        ndsPrice: 'Под запрос',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '12400 ₽',
        ndsPrice: '14300 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '12100 ₽',
        ndsPrice: '14000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '11500 ₽',
        ndsPrice: '13200 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      //,
      //   {
      //   price: '48 ₽/км',
      //   ndsPrice: '55 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },
      //   {
      //   price: '1200 ₽/ч',
      //   ndsPrice: '1400 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  }, {
    title: '11-14 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_11-14.svg',
      alt: 'Паллета 11-14',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 5 тонн</span><span class="d-block">11-14 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: 'Под запрос',
        ndsPrice: 'Под запрос',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '13000 ₽',
        ndsPrice: '15000 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '12700 ₽',
        ndsPrice: '14600 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '12000 ₽',
        ndsPrice: '13800 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      //,
        // {
      //   price: '48 ₽/км',
      //   ndsPrice: '55 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },
      //   {
      //   price: '1200 ₽/ч',
      //   ndsPrice: '1400 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  }, {
    title: '15-17 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_15-17.svg',
      alt: 'Паллета 15-17',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 10 тонн</span><span class="d-block">15-17 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: 'Под запрос',
        ndsPrice: 'Под запрос',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '17000 ₽',
        ndsPrice: '19600 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '16600 ₽',
        ndsPrice: '19100 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '15500 ₽',
        ndsPrice: '17900 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },
      //   {
      //   price: '48 ₽/км',
      //   ndsPrice: '55 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },
      //   {
      //   price: '1200 ₽/ч',
      //   ndsPrice: '1400 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  },{
    title: '18-32 шт.',
    items: [{
      image: '/assets/images/tariff/pallet_to_18-32.svg',
      alt: 'Паллета 18-32',
      imageClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center',
      title: '<span class="d-block">До 20 тонн</span><span class="d-block">18-32 паллет</span>',
      titleClass: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md',
      additionalFields: [{
        price: 'Под запрос',
        ndsPrice: 'Под запрос',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '24200 ₽',
        ndsPrice: '27900 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '23700 ₽',
        ndsPrice: '27300 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      },{
        price: '22700 ₽',
        ndsPrice: '26100 ₽',
        class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      }
      //,
      //   {
      //   price: '50 ₽/км',
      //   ndsPrice: '60 ₽/км',
      //   class: 'col-12 col-md mb-16-xs mb-0-md p-0-xs pr-15-md align-self-center'
      // },
      //   {
      //   price: '1750 ₽/ч',
      //   ndsPrice: '2000 ₽/ч',
      //   class: 'col-12 col-md-auto w-percent-100-md mw-200-md mb-16-xs mb-0-md p-0-xs align-self-center'
      // }
      ]
    }]
  }]

  description3 = 'Компания «Акро» - это надежный и квалифицированный партнер в сфере логистики для любого бизнеса. Мы выполняем доставку сборных грузов до складов всех маркетплейсов России - wildberries, яндекс маркет и ozon. Работаем как с физическими, так и с юридическими лицами. Вам не нужно иметь собственный автопарк, чтобы отправить свои товары - вот уже более 5 лет мы осуществляем доставку во все центры интернет-магазинов, строго соблюдая все регламенты. Данный вид услуги предполагает большую ответственность, что заключается не только в соблюдении сроков доставки, но и норм, предъявляемых к грузам интернет-площадкой. Мы отслеживаем все последние изменения в правилах Озон, поэтому наши клиенты могут быть уверены в том, что перевозка товара будет произведена качественно и в срок.'

  blockWithItems = {
    title: 'Требования OZON <br> к поставщикам',
    description: 'OZON предъявляет высокие требования к своим поставщикам и подготовке к отгрузке, а выбор компании по оказанию логистических услуг играет крайне важную роль. ТК «АКРО» гарантирует выполнение всех обязательных условий маркетплейса:',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: 'assets/images/shipping-on-ozon/shipping-on-ozon-1.svg',
      alt: 'Пунктуальность',
      imageClass: '-bottom-50-xs right-14-xs position-absolute-lg',
      title: 'Пунктуальность',
      description: 'поставщик обязан прибыть на Ozon не позднее чем за 30 минут планового времени прибытия, указанного в листе согласования, и не ранее 1,5 часов до него - в противном случае склад может отказать в принятии);',
      class: 'p-30-xs bg-purple border-grey3 text-center-lg'
    },{
      image: 'assets/images/shipping-on-ozon/shipping-on-ozon-2.svg',
      alt: 'Внимательность',
      imageClass: '-bottom-50-xs right-14-xs position-absolute-lg',
      title: 'Внимательность',
      description: 'мы тщательно следим за тем, чтобы все документы были корректно оформлены',
      class: 'p-30-xs bg-purple border-grey3 text-center-lg'
    },{
      image: 'assets/images/shipping-on-ozon/shipping-on-ozon-3.svg',
      alt: 'Компетентность',
      imageClass: '-bottom-50-xs right-14-xs position-absolute-lg',
      title: 'Компетентность',
      description: 'мы детально изучили данные из регламента по поставкам на фулфилмент-точки OZON и всегда готовы оказать помощь в полном объеме по упаковке, маркировке товаров, оформлении документов и в других возникающих вопросах<br><br>',
      class: 'p-30-xs bg-purple border-grey3 text-center-lg'
    }]
  }

  blockWithItems2 = {
    title: '',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'наличие и предоставление сопроводительных документов;',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'соблюдение регламента погрузо-разгрузочных работ на складе;',
      class: 'p-20-xs border-blue'
    },{
      image: '',
      title: '',
      description: 'соответствие транспортных средств площадке (двери должны открываться на 270 градусов, ширина погрузо-разгрузочного проема грузового отсека - не менее 2 м, а грузоподъемность автомобиля - от 3,5 тонн, с высотой борта от 1,2 метра).',
      class: 'p-20-xs border-blue'
    }]
  }

  blockWithItems3 = {
    title: 'Наши тарифы:',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Отправки груза до терминала маркетплейса ozon осуществляются по фиксированным тарифам, с которыми вы можете ознакомиться в соответствующем разделе. Минимальная сумма - 4710 ₽ без НДС (Ozon-Истра). Цены поставки в Хоругвино и Тверь отличаются. Все цены указаны в фиксированном формате. Оплата производится безналичным способом после оказания услуги.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Оформить доставку в маркетплейс Озон, получить расчет стоимости по тарифам перевозки сборных грузов или индивидуальное коммерческое предложение вы можете на нашем сайте. Чтобы поставить товары на следующий день после оформления услуги, подайте заявку до 18:00. Наш водитель приедет и заберет груз в течение двух часов, после того как вы отправите заказ на доставку. ',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: '',
      description: 'Не забудьте оставить ваши контакты - телефон и электронную почту, а также адрес вашего склада или производства. Заполняя онлайн форму, вы принимаете нашу политику конфиденциальности и соглашаетесь на обработку ваших персональных данных.',
      class: 'p-20-xs border-blue'
    }]
  }

  blockWithItems5 = {
    title: 'Требования к грузу озон',
    description: 'Есть бесплатная возможность — ценные и дорогостоящие грузы индивидуально застраховать. <br><br>Отправки до терминала маркетплейса Ozon сразу осуществляются по фиксированным тарифам, с которыми вы можете ознакомиться в соответствующем разделе нашего сайта. Минимальная сумма заказа — 4710 руб (склад Ozon — Истра). Цены поставки на склад в Хоругвино и Тверь отличаются. Все цены указаны на нашем сайте. Оплата производится безналичным способом после оказания услуги. <br><br>Высокие требования к поставщикам OZON создают необходимость тщательного выбора партнера по оказанию логистических услуг. Компания «АКРО» гарантирует выполнение всех обязательных условий, согласно рекомендациям маркетплейса Ozon. На Ozon принимают грузы габаритами не более 120 см в длину и весом до 20 кг, и длинномерные — до 5 кг, 200 × 30 × 30. На складе Хоругвино также есть ограничение по высоте и ширине — до 60 и 80 см соответственно. Продажа более крупных единиц товаров доступна только со своих складов.',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: 'Принимаются',
      description: 'Товары для отгрузки необходимо аккуратно сложить на поддон (допускается укладка груза в транспортную коробку), при этом на каждой из паллет обязательно должен быть информационный ярлык, распечатанный из личного кабинета. Чтобы получить более подробную консультацию по доставке товаров до маркетплейса OZON, вы можете заполнить и отправить заявку, наши менеджеры свяжутся с вами в самое ближайшее время. Также ориентировочные сроки и стоимость доставки вы можете узнать по телефону 8 (499) 490-37-81. Можно писать нам на whatsapp',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: 'Не принимаются',
      description: 'Хрупкий груз без предварительного согласования<br><br>Крупногабаритный груз<br><br>Опасный груз<br><br>Запрещенный груз',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: '',
      title: 'Требования к маркировке',
      description: 'При доставке грузов в пункт маркетплейса Ozon от поставщика требуется организовать правильное документальное оформление коробов и европаллет. К коробкам прикладывается индивидуальный маркировочный лист. Также нужны паллетные листы, где указаны дата, номера заказов на перевозку.',
      class: 'p-20-xs border-blue'
    }]
  }

  description2 = 'OZON является крупнейшей в России площадкой интернет-коммерции, которая работает с огромным количеством продавцов, покупателей и поставщиков, многим из которых необходим надежный и квалифицированный партнер в сфере логистики. Вам не нужно содержать собственный автопарк, чтобы отправить свои товары на площадки электронной торговли. Компания «Акро» вот уже более 5 лет осуществляет доставку во все распределительные центры таких интернет-магазинов, как WILDBERRIES, ozon. Мы строго соблюдаем все регламенты в соответствии с их схемой работы (fbo, fbs, dbs). Поскольку правила площадки динамичны и периодически появляются новые нюансы, мы регулярно мониторим раздел “новости” на появление обновлений, что позволяет вам быть уверенными в актуальности нашей информации.'

  faq = [{
    title: "Оказываете ли вы дополнительные услуги фулфилмента и маркировки товара?",
    description: "На сегодняшний момент мы не предлагаем и не оказываем подобных услуг, но в ближайшей перспективе планируем внедрить эти направления, в том числе и начать хранение вашей продукции на нашем складе (складские услуги)."
  }, {
    title: "Как происходит оплата?",
    description: "Выберите подходящий для вас вариант: постоплату, которая производится на наш расчетный счет после оказания услуги доставки груза, или предоплату (в этом случае будет действовать акция и стоимость перевозки будет ниже)."
  }, {
    title: "Как осуществляется доставка груза до маркетплейса ozon?",
    description: "Продавец товара подготавливает документы на доставку и бронирует дату разгрузки на Ozon. Остальную работу делают наши сотрудники. Если вы хотите, мы заберем товары с вашего производства в строго оговоренное время, которое вы назначите, и доставим груз на склад маркетплейса. При отсутствии нужных документов или присутствии ошибок в них, приема поставок не будет. Очень важно внимательно подготовить все необходимое и учесть особенности склада. Если на Ozon отказывают в приемке груза, мы решаем проблему самостоятельно или оперативно уведомляем об этом клиента."
  }, {
    title: "Какие машины вы предоставляете?",
    description: "В нашем автопарке есть различные виды транспорта для доставки: от каблука грузоподъемностью 500 кг до фуры грузоподъемностью до 20 тонн, с разными типами кузова — тент, рефрижератор и другие. При поставке транспорта мы соблюдаем все нормы маркетплейсов Ozon."
  }, {
    title: "Каким образом вы возвращаете оригиналы документов?",
    description: "Два раза в месяц мы привозим оригиналы документов к вам в офис по адресу, указанному в личном кабинете при регистрации курьером или по почте."
  }, {
    title: "Где территориально вы находитесь?",
    description: "Наш офис расположен по адресу: город Москва, ул.Щёлковское ш., д. 2а."
  }, {
    title: "Где можно изучить информацию о том, какие вакансии открыты в вашей компании?",
    description: "Все открытые вакансии размещены на сайте hh.ru"
  },{
    title: "Каким образом можно оформить отправку нашего груза на Ozon?",
    description: "Чтобы поставить товар на Ozon на следующий день после оформления, оставьте заявку на услугу до 18:00. Также мы принимаем и выполняем работы, в зависимости от пожелания клиента. Мы без задержки забираем упакованный товар с вашего производства. Не забудьте указать ваши контакты — номер телефона и электронную почту, а также адрес вашего склада или производства материалов. Заполняя онлайн информацию и нажимая кнопку “Отправить”, вы знакомитесь с нашей политикой конфиденциальности и соглашаетесь на обработку ваших персональных данных."
  },{
    title: "Как зарегистрироваться в личном кабинете?",
    description: "Главная страница <a href='https://tk-akro.ru/'>https://tk-akro.ru/</a> содержит ссылку «Личный кабинет», нажимая на которую, вы переходите в форму и заполняете обязательные поля, даете разрешение на хранение и обработку персональных данных и нажимаете кнопку «зарегистрироваться». После этого вы можете войти в сервис и вам будут доступны все его преимущества, в том числе загрузка документов, бесплатное онлайн отслеживание груза при доставке и калькулятор, с помощью которого вы можете рассчитать стоимость. Обратите внимание, что заполняя онлайн -форму, вы принимаете нашу политику конфиденциальности и даете согласие на обработку ваших персональных данных. Также у нас есть полезная информация о мире грузоперевозок, включая актуальные новости."
  }]

  blockWithItems4 = {
    title: 'Наша компания в процессе своей деятельности успела зарекомендовать себя как надежный и честный партнер. Нас выбирают, потому что мы гарантируем:',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Индивидуальный подход к расчету стоимости тарифа: специальные условия, акции и скидки постоянным клиентам. Мы контролируем все этапы доставки от хранения, погрузки до выгрузки в Москве, Московской обл и других городах РФ',
      class: 'p-0-xs'
    },{
      image: '',
      title: '',
      description: 'Помощь персонального менеджера, который даст ответы на все ваши вопросы, построит оптимальный маршрут и оформит все сопроводительные документы. Поддержка на всех этапах. Мы работаем по всей стране: забираем товары в Москве и области, отвозим груз на Ozon по всей России.',
      class: 'p-0-xs'
    },{
      image: '',
      title: '',
      description: 'Удобный личный кабинет, в котором вы легко можете создавать новые заказы и отследить текущие, хранить и скачивать документы. Также в нем есть калькулятор , которым можно воспользоваться для расчета стоимости перевозки за км.',
      class: 'p-0-xs'
    },{
      image: '',
      title: '',
      description: 'Большое разнообразие транспортных средств для аренды: доставляем моно- и микс-паллеты, малогабаритный и крупногабаритный груз от 1 кг до 20 тонн. Вы можете выбрать например тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какой автомобиль заказать, наши менеджеры всегда помогут с выбором.',
      class: 'p-0-xs'
    }]
  }

  tags = {
    title: 'Теги',
      items: [{
      class: '',
      title: '',
      children: [{
        title: 'Доставка груза на маркетплейсы',
        link: '/marketplejsy'
      },{
        title: 'Доставка груза на Ozon',
        link: '/marketplejsy/ozon/'
      },{
        title: 'Доставка груза на Wildberries',
        link: '/marketplejsy/wildberries/'
      },{
        title: 'Доставка груза для магазинов',
        link: ''
      },{
        title: 'Упаковка товаров',
        link: '/marketplejsy'
      },{
        title: 'Доставка 3 тонны',
        link: '/avtopark/gruzoperevozki-3tonny/'
      },{
        title: 'Доставка 5 тонн',
        link: '/avtopark/gruzoperevozki-5tonn/'
      },{
        title: 'Доставка 10 тонн',
        link: '/avtopark/gruzoperevozki-10tonn/'
      },{
        title: 'Доставка 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn/'
      }],
    }]
  };

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Доставка до маркетплейса Озон товара и груза в Москве, СПБ, России' });
    this.meta.addTag({ property: 'og:description', content: '✅ Доставка до склада Ozon ✅ Пункт доставки Озон ✅ Паллетная доставка на склад Озон ✅ Страхование груза ✅ Звоните сейчас 8 (499) 350-90-02' });
    this.meta.addTag({ name: 'description', content: '✅ Доставка до склада Ozon ✅ Пункт доставки Озон ✅ Паллетная доставка на склад Озон ✅ Страхование груза ✅ Звоните сейчас 8 (499) 350-90-02' });
    this.titleService.setTitle('Доставка до маркетплейса Озон товара и груза в Москве, СПБ, России');
  }
}
