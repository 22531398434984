import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-tent-furgon',
  templateUrl: './tent-furgon.component.html',
  styleUrls: ['./tent-furgon.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TentFurgonPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/avtopark',
      linkTitle: 'Автопарк'
    }, {
      link: '',
          linkTitle: 'Тент-фургон'
        }]
  }

  carActive   = 0;
  typeActive  = 0;

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/05t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3900 ₽',
          ndsPrice: '4500 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '9500 ₽',
          ndsPrice: '11000 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '24 ₽/км',
        ndsCheckout: '27 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '4350 ₽',
          ndsPrice: '5000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '25 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '5760 ₽',
          ndsPrice: '6600 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '13000 ₽',
          ndsPrice: '15000 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '29 ₽/км',
        pass: '700 ₽/800 ₽',
        ndsPass: '800 ₽/900 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '6600 ₽',
          ndsPrice: '7500 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '36 ₽/км',
        pass: '800 ₽/1000 ₽',
        ndsPass: '900 ₽/1100 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '8400 ₽',
          ndsPrice: '9450 ₽',
          advPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '36 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '850 ₽/1100 ₽',
        ndsPass: '1000 ₽/1200 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">1300 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '45 ₽/км',
        pass: '1100 ₽/1200 ₽/1650 ₽',
        ndsPass: '1200 ₽/1400 ₽/1900 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '11200 ₽',
          ndsPrice: '13500 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1550 ₽/2100 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '12000 ₽',
          ndsPrice: '14000 ₽',
          advPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1700 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '55 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1650 ₽ /2100 ₽ /2600 ₽',
        ndsPass: '1900 ₽/2500 ₽/3000 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '18000 ₽',
          ndsPrice: '20800 ₽',
          advPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '65 ₽/км',
        ndsCheckout: '70 ₽/км',
        pass: '1700 ₽/2200 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    }]
  },{
    title: 'Бортовой',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/05t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">Бизнес</span> <span class="d-block">Включено 70 км по Москве</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/05t.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">Бизнес+</span> <span class="d-block">Включено 100 км по Москве</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  },{
    title: 'Манипулятор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/05t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  }]

  blockWithItems = {
    title: 'Профессиональные сотрудники',
    description: '',
    class: 'col-12 col-md-4 text-center',
    items: [{
      image: '/assets/images/tent-furgon/tent-furgon2.svg',
      alt: 'Маршрут',
      title: '',
      description: 'Наши сотрудники составят для Вас удобный маршрут перевозки крупногабаритного груза. Мы постараемся полностью сократить любые Ваши дополнительные расходы',
      class: 'p-20-xs bg-purple border-grey3 d-flex flex-column align-items-center justify-content-around'
    },{
      image: '/assets/images/tent-furgon/tent-furgon9.svg',
      alt: 'Информирование',
      title: '',
      description: 'Менеджер будет в течение всего времени информировать Вас о статусе перевозки груза на тентованном автомобиле',
      class: 'p-20-xs bg-purple border-grey3 d-flex flex-column align-items-center justify-content-around'
    },{
      image: '/assets/images/tent-furgon/tent-furgon3.svg',
      alt: 'Консультация',
      title: '',
      description: 'Мы даем Вам возможность бесплатной консультации от наших специалистов. Будем рады проконсультировать Вас о грузоперевозках в любое удобное время',
      class: 'p-20-xs bg-purple border-grey3 d-flex flex-column align-items-center justify-content-around'
    }]
  }

  blockWithItems2 = {
    title: 'Грузоперевозки под контролем',
    description: '',
    class: 'col-12 col-md-6 text-center',
    items: [{
      image: '',
      title: '',
      description: 'В зависимости от количества и типа груза будет сформирован маршрут (предварительная схема) по Российской Федерации, который будет учитывать особенности перевоза. Наши специалисты всегда на связи, организуют все таким образом, чтобы Вы не платили лишние деньги за услуги.',
      class: 'text-left'
    },{
      image: '',
      title: '',
      description: 'В зависимости от количества и типа груза будет сформирован маршрут (предварительная схема) по Российской Федерации, который будет учитывать особенности перевоза. Наши специалисты всегда на связи, организуют все таким образом, чтобы Вы не платили лишние деньги за услуги.',
      class: 'p-20-xs text-left border-blue'
    }],
  }

  blockWithItemsAdvanced = {
    title: 'Тентованные бизнес-фургоны для перевозки Ваших грузов',
    description: '',
    rowClass: '',
    items: [{
      class: 'col-12 col-md-6',
      subitems: [{
        image: '',
        title: '',
        description: 'Для перевозки любых грузов можно воспользоваться нашими тентованными фургонами бизнес класса. Фургон тент довезет все необходимые вещи в необходимое местоположение за короткий срок. Машина будет подана в течение часа.',
        descriptionClass: '',
        class: 'pt-20-xs pb-20-xs text-left'
      }]
    },{
      class: 'col-12 col-md-6',
      subitems: [{
        image: '',
        title: 'Бизнес +',
        description: 'является наилучшим вариантом для перевозки<br> грузов на отдаленные расстояния',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs'
      }]
    },{
      class: 'col-12 col-md-3',
      subitems: [{
        image: '',
        title: '70 км',
        description: 'бизнес включает в себя проезд по Москве',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs'
      }]
    },{
      class: 'col-12 col-md-3',
      subitems: [{
        image: '',
        title: 'минимум на 4 часа',
        description: 'можно забронировать автомобиль',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs'
      }]
    },{
      class: 'col-12 col-md-3 text-center',
      subitems: [{
        image: '',
        title: '100 км',
        description: 'по Москве будут включены.',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs'
      }]
    },{
      class: 'col-12 col-md-3 text-center',
      subitems: [{
        image: '',
        title: '8 часов',
        description: 'минимальное время проезда',
        descriptionClass: '',
        class: 'p-20-xs text-left bg-purple border-grey3 b-r-16-xs'
      }]
    }]

  }

  blockWithItems3 = {
    title: 'Что вы получите',
    description: '',
    class: 'col-12 col-md-4 text-center',
    items: [{
      image: '/assets/images/tent-furgon/tent-furgon9.svg',
      alt: 'Отбор',
      title: '',
      description: 'Все водители грузовиков в нашей компании проходят тщательный отбор. Доставкой грузов на тентованных автомобилях занимаются только те сотрудники, которые имеют определенную квалификацию и большой опыт работы',
      class: 'p-20-xs border-blue'
    },{
      image: '/assets/images/tent-furgon/tent-furgon4.svg',
      alt: 'Обучение',
      title: '',
      description: 'Каждый наш сотрудник проходит дополнительно специальное обучение',
      class: 'p-20-xs border-blue'
    },{
      image: '/assets/images/tent-furgon/tent-furgon1.svg',
      alt: 'Проверка',
      title: '',
      description: 'Каждый водитель в процессе работы проверяется на наличие любых недочетов и ошибок, чтобы успеть вовремя их исправить. Перед началом перевозки Вашего груза каждый водитель в обязательном порядке пройдет процедуру инструктажа',
      class: 'p-20-xs border-blue'
    }],
  }

  blockWithItemsAdvanced2 = {
    title: 'Качественно и профессионально',
    description: '',
    rowClass: 'bg-purple b-r-16-xs border-grey3',
    items: [{
      class: 'col-12 col-md-4 text-center',
      subitems: [{
        image: '/assets/images/tent-furgon/tent-furgon2.svg',
        alt: 'Пути',
        title: '',
        description: 'Наши логисты в команде составят для заказчика удобные варианты пути следования грузов таким образом, чтобы в полной мере минимизировать лишние затраты перед отправкой.',
        descriptionClass: 'text-left text-center-lg',
        class: 'p-20-xs pb-20-xs'
      }]
    },{
      class: 'col-12 col-md-4 text-center',
      alt: 'Консультация',
      subitems: [{
        image: '/assets/images/tent-furgon/tent-furgon5.svg',
        title: '',
        description: 'При необходимости возможно воспользоваться услугой бесплатной консультации от наших специалистов. Мы ответим на вопросы о грузоперевозках и организуем весь следующий процесс.',
        descriptionClass: 'text-left text-center-lg',
        class: 'p-20-xs b-r-16-xs'
      }]
    },{
      class: 'col-12 col-md-4 text-center',
      subitems: [{
        image: '/assets/images/tent-furgon/tent-furgon12.svg',
        alt: 'Информирование',
        title: '',
        description: 'В течение всего следования Ваш персональный менеджер отдела логистики будет информировать о статусе перевозки.',
        descriptionClass: 'text-left text-center-lg',
        class: 'p-20-xs b-r-16-xs'
      }]
    }]
  }
}
