import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-bullock',
  templateUrl: './trucking-bullock.component.html',
  styleUrls: ['./trucking-bullock.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingBullockPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: 'avtopark',
      linkTitle: 'Наш автопарк'
    }, {
      link: '',
      linkTitle: 'Грузоперевозки бычок'
        }]
  }

  carActive   = 0;
  typeActive  = 0;
  header = [{
    title: 'Категория',
    class: 'col-auto w-percent-100-md mw-110-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-auto w-percent-100-md mw-140-md p-0-xs pr-15-xs'
  },{
    title: '4 часа',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '5 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '6 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '7 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '8 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '9 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: 'Выезд за МКАД',
    class: 'col-auto w-percent-100-md mw-140-md white-space-pre-lg p-0-xs pr-15-xs'
  }, {
    title: 'Пропуск<br>ТТК / СК / МКАД',
    class: 'col-auto w-percent-100-md mw-130-md p-0-xs'
  }]

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/05t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3900 ₽',
          ndsPrice: '4500 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '9500 ₽',
          ndsPrice: '11000 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '24 ₽/км',
        ndsCheckout: '27 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '4350 ₽',
          ndsPrice: '5000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '25 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '5760 ₽',
          ndsPrice: '6600 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '13000 ₽',
          ndsPrice: '15000 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '29 ₽/км',
        pass: '700 ₽/800 ₽',
        ndsPass: '800 ₽/900 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '6600 ₽',
          ndsPrice: '7500 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '36 ₽/км',
        pass: '800 ₽/1000 ₽',
        ndsPass: '900 ₽/1100 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '8400 ₽',
          ndsPrice: '9450 ₽',
          advPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '36 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '850 ₽/1100 ₽',
        ndsPass: '1000 ₽/1200 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">1300 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '45 ₽/км',
        pass: '1100 ₽/1200 ₽/1650 ₽',
        ndsPass: '1200 ₽/1400 ₽/1900 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '11200 ₽',
          ndsPrice: '13500 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1550 ₽/2100 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '12000 ₽',
          ndsPrice: '14000 ₽',
          advPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1700 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '55 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1650 ₽ /2100 ₽ /2600 ₽',
        ndsPass: '1900 ₽/2500 ₽/3000 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '18000 ₽',
          ndsPrice: '20800 ₽',
          advPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '65 ₽/км',
        ndsCheckout: '70 ₽/км',
        pass: '1700 ₽/2200 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  }]

  description = 'Компания «Акро» успешно оказывает полный спектр услуг по перевозке различных видов грузов с 2016 года. В нашу географию входит не только Москва и Московская область, но и другие регионы России. Наш автопарк включает в себя большой выбор грузовых автомобилей — от машины каблук и газель до рефрижератора и фуры.'

  blockWithItems = {
    title: 'Наши преимущества',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: 'assets/images/trucking-bullock/trucking-bullock-1.svg',
      alt: 'Выбор',
      title: 'Большой выбор',
      description: 'Мы учитываем, что заказ на доставку \n' +
        'и перевозку может потребоваться в период пробок, поэтому наш автопарк укомплектован подходящим транспортом, который позволяет легко перемещаться \n' +
        'по узким улицам центра Москвы \n' +
        'и доставлять грузы по любым адресам. ',
      class: 'p-20-xs bg-purple border-grey3 text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-2.svg',
      alt: 'Пунктуальность',
      title: 'Пунктуальность',
      description: 'В нашей пунктуальности убедилось большое количество клиентов, ставших постоянными. ',
      class: 'p-20-xs bg-purple border-grey3 text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-3.svg',
      alt: 'Отзывы',
      title: 'Положительные отызывы',
      description: 'Нас рекомендуют знакомым и партнерам \n' +
        'по бизнесу, о чем свидетельствуют многочисленные положительные отзывы \n' +
        'о нашей работе.',
      class: 'p-20-xs bg-purple border-grey3 text-center'
    }]
  }

  blockWithItems2 = {
    title: 'Почему клиенты выбирают нас:',
    description: 'С транспортной компанией «АКРО» вы можете быть уверены, что перевозки грузов на автомобиле средней тоннажности будут осуществлены быстро \n' +
      'и аккуратно. Нас выбирают владельцы малого <br> Нас выбирают владельцы малого и среднего бизнеса из Москвы, Московской области, Санкт-Петербурга и других городов России.',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: 'assets/images/trucking-bullock/trucking-bullock-1.svg',
      alt: 'Сервис',
      title: 'Отличный сервис',
      description: '',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-2.svg',
      alt: 'Отзывы',
      title: 'Положительные отзывы',
      description: '',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-3.svg',
      alt: 'Скорость',
      title: 'Быстрые грузоперевозки',
      description: '',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-3.svg',
      alt: 'Тарифы',
      title: 'Выгодные тарифы',
      description: '',
      class: 'p-20-xs border-blue text-center'
    }]
  }


  blockWithItems3 = {
    title: 'Профессиональные сотрудники',
    description: '',
    class: 'col-12 col-md-4',
    items: [{
      image: 'assets/images/trucking-bullock/trucking-bullock-1.svg',
      alt: 'Маршрут',
      title: '',
      description: 'Наши сотрудники составят для Вас удобный маршрут бычка. Мы постараемся полностью сократить Ваши дополнительные расходы грузоперевозок на расстояния',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-2.svg',
      alt: 'Менеджер',
      title: '',
      description: 'Менеджер будет в течение всего времени рейса информировать Вас о статусе перевозимого груза в пути',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-3.svg',
      alt: 'Консультация',
      title: '',
      description: 'Мы даем Вам возможность бесплатной консультации от наших специалистов. Будем рады проконсультировать Вас об условиях грузоперевозкок на бычке, рассчитать стоимость и поделиться и прочей информации в удобное время',
      class: 'p-20-xs border-blue text-center'
    }]
  }


  blockWithItems4 = {
    title: 'Квалифицированные водители',
    description: '',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: 'assets/images/trucking-bullock/trucking-bullock-1.svg',
      alt: 'Отбор',
      title: '',
      description: 'Все водители бычков проходят тщательный отбор. Поставкой на машине бычок занимаются лучшие и только те, кто имеют определенную квалификацию и большой опыт работы',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-2.svg',
      alt: 'Проверка',
      title: '',
      description: 'Каждый в процессе работы проверяется на наличие любых недочетов и ошибок, чтобы успеть вовремя их исправить. Перед началом работы каждый в обязательном порядке пройдет процедуру инструктажа',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-bullock/trucking-bullock-3.svg',
      alt: 'Обучение',
      title: '',
      description: 'Каждый наш специалист проходит дополнительно специальное обучение по грузоперевозкам на бычке',
      class: 'p-20-xs border-blue text-center'
    }]
  }

  tags = {
    title: 'Автомобильные грузоперевозки',
    class: '',
    items: [{
      class: '',
      title: '',
      children: [{
        title: 'Квартирный переезд',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Офисный переезд',
        link: '/gruzoperevozki-dlya-yur-lic'
      },{
        title: 'Переезд интернет-магазинов',
        link: '/gruzoperevozki-dlya-yur-lic'
      },{
        title: 'Перевозка с температурным режимом',
        link: '/avtopark/refrizheratornye-perevozki'
      },{
        title: 'Перевозки 3 тонны',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: '5 тонн',
        link: '/avtopark/gruzoperevozki-5tonn'
      },{
        title: '10 тонн',
        link: '/avtopark/gruzoperevozki-10tonn'
      },{
        title: 'Переезд на газели',
        link: '/avtopark/gruzoperevozki-na-gazeli'
      },{
        title: 'Переезд на машине',
        link: '/avtopark/gruzoperevozki-kabluk'
      },{
        title: 'Дачный переезд',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Догруз и переезд по Москве',
        link: '/gruzoperevozki-po-moskve-i-mo'
      },{
        title: 'Загрузка и упаковка больших партий 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn'
      }]
    }]
  };

  faq = [{
    title: "В каких случаях может потребоваться грузоперевозка Бычком?",
    description: "Если вам предстоит квартирный, дачный или офисный переезд, нужно доставить бытовую технику, стройматериалы, нужна поставка товаров со склада на розничные точки продаж — обращайтесь в «Акро» и заказывайте среднетоннажный грузовик ЗИЛ Бычок."
  }, {
    title: "Какова стоимость транспортировки грузов Бычком?",
    description: "Стандартная стоимость заказа автомобиля типа фургон составляет  7 350 руб (стоимость одного часа — 1 050 руб, минимальный заказ — 6 часов). В тарифе «Бизнес» уже включено 70 км по Москве. Транспортировка за пределы МКАД тарифицируется отдельно. Время подачи автомобиля для автоперевозки составляет 1 час."
  }, {
    title: "Как осуществляется оплата?",
    description: "Выберите удобный для вас способ оплаты перевозки: постоплату, которая производится на наш расчетный счет после оказания услуги, или предоплату (в этом случае будет действовать акция, и стоимость перевозки будет ниже). Также мы предоставим вам скидку на первый заказ."
  }, {
    title: "Даете ли вы гарантию на сохранность груза?",
    description: "Да, фирма на 100% гарантирует целостность вашего товара и бесплатно застрахуем ваш груз."
  }, {
    title: "Как заказать услугу грузоперевозки бычком?",
    description: "Для заказа авто вам доступны несколько вариантов на выбор: <br><br>• вы можете оставить заявку на звонок, заполнив форму обратной связи на сайте (менеджер свяжется с вами в течение двух минут); <br>• создать новую заявку в личном кабинете; <br>• позвонить нам по телефону 8 (499) 350-90-02; <br>• написать на почту info@tk-akro.ru <br><br>Заполняя онлайн-форму на сайте, вы принимаете нашу политику конфиденциальности и соглашаетесь на обработку ваших персональных данных. После уточнения всей информации, наш менеджер подбирает для вас оптимальную технику для грузоперевозки и делает расчет стоимости вашего заказа. Затем мы заключаем с вами договор и в назначенный срок осуществляется перевозка."
  }, {
    title: "Предоставляете ли вы дополнительные услуги грузчиков?",
    description: "Да, если вам необходима помощь при погрузке/разгрузке вашего товара, помимо доставки, вам стоит заказать и оформить эту услугу. Цены будут зависеть от того, сколько весит ваш груз, и его габаритов. В зависимости от этих параметров погрузка и разгрузка может быть осуществлена водителем или либо на помощь вам придут грузчики."
  }, {
    title: "Где можно узнать информацию о том, какие вакансии открыты в вашей компании?",
    description: "Все открытые вакансии размещены на сайте hh.ru"
  }];

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Грузоперевозки бычок по Москве и области дешево' });
    this.meta.addTag({ property: 'og:description', content: '✅ Грузоперевозки бычок в Москве от 1150 руб ✅ Подача автомобиля в день обращения ✅ Закажите ЗИЛ бычок сейчас! Звоните +7 (499) 350-90-02' });
    this.titleService.setTitle('Грузоперевозки бычок по Москве и области дешево');
  }
}
