import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-furies',
  templateUrl: './trucking-furies.component.html',
  styleUrls: ['./trucking-furies.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingFuriesPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/avtopark',
      linkTitle: 'Автопарк'
    }, {
      link: '',
          linkTitle: 'Грузоперевозки фуры'
        }]
  }


  carActive   = 0;
  typeActive  = 1;
  header = [{
    title: 'Категория',
    class: 'col-auto w-percent-100-md mw-110-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-auto w-percent-100-md mw-140-md p-0-xs pr-15-xs'
  },{
    title: '4 часа',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '5 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '6 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '7 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '8 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '9 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: 'Выезд за МКАД',
    class: 'col-auto w-percent-100-md mw-140-md white-space-pre-lg p-0-xs pr-15-xs'
  }, {
    title: 'Пропуск<br>ТТК / СК / МКАД',
    class: 'col-auto w-percent-100-md mw-130-md p-0-xs'
  }]

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/05t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3900 ₽',
          ndsPrice: '4500 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '9500 ₽',
          ndsPrice: '11000 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '24 ₽/км',
        ndsCheckout: '27 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '4350 ₽',
          ndsPrice: '5000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '25 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '5760 ₽',
          ndsPrice: '6600 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '13000 ₽',
          ndsPrice: '15000 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '29 ₽/км',
        pass: '700 ₽/800 ₽',
        ndsPass: '800 ₽/900 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '6600 ₽',
          ndsPrice: '7500 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '36 ₽/км',
        pass: '800 ₽/1000 ₽',
        ndsPass: '900 ₽/1100 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '8400 ₽',
          ndsPrice: '9450 ₽',
          advPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '36 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '850 ₽/1100 ₽',
        ndsPass: '1000 ₽/1200 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">1300 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '45 ₽/км',
        pass: '1100 ₽/1200 ₽/1650 ₽',
        ndsPass: '1200 ₽/1400 ₽/1900 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '11200 ₽',
          ndsPrice: '13500 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1550 ₽/2100 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '12000 ₽',
          ndsPrice: '14000 ₽',
          advPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1700 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '55 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1650 ₽ /2100 ₽ /2600 ₽',
        ndsPass: '1900 ₽/2500 ₽/3000 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '18000 ₽',
          ndsPrice: '20800 ₽',
          advPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '65 ₽/км',
        ndsCheckout: '70 ₽/км',
        pass: '1700 ₽/2200 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  }]

  description = 'Перевозка грузов фурами — одна из наиболее актуальных и востребованных услуг на рынке автоперевозок. Транспортировка подобным видом автомобиля может осуществляться в междугородном сообщении по Московской области и другим регионам России.\n' +
    'Заказать фуру для перевозки ваших грузов вы можете в транспортной компании «Акро». Мы являемся одной из лучших компаний в сегменте перевозок с опытом успешной работы более 5 лет. Большой автопарк включает в себя большой выбор грузовых автомобилей, в том числе фуры, что позволяет решать разнообразные логистические задачи. Если вы планируете переезд, вам нужна перевозка крупногабаритных вещей, крупной бытовой техники, пиломатериалов, больших партий товаров — обращайтесь к нам! Выгодные тарифы, широкий спектр услуг и индивидуальный подход к каждому клиенту позволяет не только проработать оптимальный маршрут, но и сэкономить ваши денежные ресурсы и время.'

  blockWithItems = {
    title: 'Варианты кузова:',
    description: 'В зависимости от того, какой вид груза необходимо транспортировать, наши менеджеры подберут для вас один из вариантов кузова:',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: 'assets/images/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons-1.svg',
      alt: 'Борт',
      title: 'Бортовой',
      description: 'ГБортовые грузовики могут перевозить на своей платформе разные виды груза, не требующие специальных условий транспортировки, например, стройматериалы, оборудование, металлоконструкций, арматуры \n' +
        'и иные неприхотливые грузы.',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons-2.svg',
      alt: 'Тент',
      title: 'Тентованный',
      description: 'Тентованные фуры предназначены для перевозки грузов, не требующих поддержания температуры и условий, но водонепроницаемый тент при этом надежно защищает от атмосферных явлений. Для осуществления погрузки и выгрузки товара тент легко снимается.',
      class: 'p-20-xs border-blue text-center'
    },{
      image: 'assets/images/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons-3.svg',
      alt: 'Рефрижератор',
      title: 'Рефрижератор',
      description: 'Перевозки рефрижератором представляют собой доставку товаров с ограниченным сроком годности, требующих определенной температуры. Транспортное средство должно быть оснащено специальным холодильным оборудованием.',
      class: 'p-20-xs border-blue text-center'
    }]
  }

  faq = [{
    title: "Даете ли вы гарантию на сохранность груза?",
    description: "Да, мы на 100% гарантируем сохранность вашего груза при аренде нашего транспорта. Мы берем на себя полную ответственность и контролируем все этапы \n" +
      "от загрузки до разгрузки. Гарантируем отличный сервис и высокое качество услуг, о чем свидетельствуют отзывы наших довольных клиентов!"
  }, {
    title: "Сколько будет стоить арендовать манипулятор?",
    description: "Цена на услуги кранов-манипуляторов напрямую зависит от типа и грузоподъемности техники. Например, манипулятор с грузоподъемностью 3 тонны можно заказать по цене от 5250 рублей (с НДС). Узнать цены на наши услуги, арендовать манипулятор в Москве или Московской области, а также получить индивидуальное коммерческое предложение вы можете по телефону 8 (499) 350-90-02."
  }, {
    title: "Какие виды манипуляторов есть в вашем автопарке?",
    description: "Для наших клиентов доступна аренда манипуляторов с грузоподъемностью 3 тонны, 5 тонн, 7 тонн и 7 тонн (вездеход)."
  }, {
    title: "Как осуществляется аренда спецтехники у вашей компании?",
    description: "Если вам нужна перевозка особых видов грузов с помощью манипулятора, у вас есть возможность оставить заявку на нашем сайте, заполнив форму обратной связи, или через личный кабинет.  Не забудьте указать ваши контакты – номер телефона и электронную почту, а также адрес, по которому нужно забрать и доставить груз. Заполняя онлайн заявку на сайте, вы принимаете нашу политику конфиденциальности и соглашаетесь на обработку ваших персональных данных."
  }, {
    title: "Как осуществляется оплата?",
    description: "Чаще всего наши клиенты выбирают постоплату, которая производится на наш расчетный счет после оказания услуги. Также возможен расчет по предоплате \n" +
      "(в этом случае будет действовать акция и стоимость аренды и перевозки будет ниже)."
  }];

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Заказать фуру - перевозка грузов фурами по Москвы цена снижена' });
    this.meta.addTag({ property: 'og:description', content: '✅ Заказать фуру для перевозки грузов от 1340 руб ✅ Страхование грузов ✅ Подача автомобиля в день обращения ✅ Звоните +7 (499) 350-90-02' });
    this.meta.addTag({ name: 'description', content: '✅ Заказать фуру для перевозки грузов от 1340 руб ✅ Страхование грузов ✅ Подача автомобиля в день обращения ✅ Звоните +7 (499) 350-90-02' });
    this.titleService.setTitle('Заказать фуру - перевозка грузов фурами по Москвы цена снижена');
  }
}
