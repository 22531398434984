import {Component, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-trucking-up-to-twenty-tons',
  templateUrl: './trucking-up-to-twenty-tons.component.html',
  styleUrls: ['./trucking-up-to-twenty-tons.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TruckingUpToTwentyTonsPage {

  breadcrumbs = {
    items: [{
      link: '/',
      linkTitle: 'Главная /'
    }, {
      link: '/avtopark',
      linkTitle: 'Наш автопарк'
    }, {
      link: '',
          linkTitle: 'Грузоперевозки 20 тонн'
        }]
  }

  carActive   =  0;
  typeActive  =  4;
  header = [{
    title: 'Категория',
    class: 'col-auto w-percent-100-md mw-110-md p-0-xs pr-15-xs'
  }, {
    title: 'Характеристики',
    class: 'col-auto w-percent-100-md mw-140-md p-0-xs pr-15-xs'
  },{
    title: '4 часа',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '5 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '6 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '7 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '8 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: '9 часов',
    class: 'col-auto w-percent-100-md mw-90-md p-0-xs pr-15-xs'
  },{
    title: 'Выезд за МКАД',
    class: 'col-auto w-percent-100-md mw-140-md white-space-pre-lg p-0-xs pr-15-xs'
  }, {
    title: 'Пропуск<br>ТТК / СК / МКАД',
    class: 'col-auto w-percent-100-md mw-130-md p-0-xs'
  }]

  tariffs = [{
    title: 'Тент-фургон',
    items: [{
      title: 'до 1,5т',
      items: [{
        image: '/assets/images/tariff/tender-wagon/05t.svg',
        alt: 'Паллета 1',
        title: '<span class="d-block">1 паллета</span> <span class="d-block">1.5 х 1 х 1м.</span>',
        business: {
          hour: 4,
          price: '3900 ₽',
          ndsPrice: '4500 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span><span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '9500 ₽',
          ndsPrice: '11000 ₽',
          advPrice: '<span class="d-block">780 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">900 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '24 ₽/км',
        ndsCheckout: '27 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 4,
          price: '4350 ₽',
          ndsPrice: '5000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">870 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1000 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '25 ₽/км',
        ndsCheckout: '28 ₽/км',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 6',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: '5760 ₽',
          ndsPrice: '6600 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '13000 ₽',
          ndsPrice: '15000 ₽',
          advPrice: '<span class="d-block">960 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '26 ₽/км',
        ndsCheckout: '29 ₽/км',
        pass: '700 ₽/800 ₽',
        ndsPass: '800 ₽/900 ₽'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 5,
          price: '6600 ₽',
          ndsPrice: '7500 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: 8,
          price: '14000 ₽',
          ndsPrice: '16000 ₽',
          advPrice: '<span class="d-block">1100 ₽ за</span> <span class="d-block">доп.час</span>',
          ndsAdvPrice: '<span class="d-block">1250 ₽ за</span> <span class="d-block">доп.час</span>'
        },
        checkout: '32 ₽/км',
        ndsCheckout: '36 ₽/км',
        pass: '800 ₽/1000 ₽',
        ndsPass: '900 ₽/1100 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/3t1.svg',
        alt: 'Паллета 10',
        title: '<span class="d-block">10 паллета</span> <span class="d-block">5 х 2.2 х 2.2м.</span>',
        business: {
          hour: 6,
          price: '8400 ₽',
          ndsPrice: '9450 ₽',
          advPrice: '<span class="d-block">1200 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '36 ₽/км',
        ndsCheckout: '40 ₽/км',
        pass: '850 ₽/1100 ₽',
        ndsPass: '1000 ₽/1200 ₽'
      }]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 7,
          price: '10400 ₽',
          ndsPrice: '12000 ₽',
          advPrice: '<span class="d-block">1300 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '40 ₽/км',
        ndsCheckout: '45 ₽/км',
        pass: '1100 ₽/1200 ₽/1650 ₽',
        ndsPass: '1200 ₽/1400 ₽/1900 ₽'
      },{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '11200 ₽',
          ndsPrice: '13500 ₽',
          advPrice: '<span class="d-block">1400 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1600 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '50 ₽/км',
        ndsCheckout: '55 ₽/км',
        pass: '1550 ₽/2100 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 7,
          price: '12000 ₽',
          ndsPrice: '14000 ₽',
          advPrice: '<span class="d-block">1500 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">1700 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '55 ₽/км',
        ndsCheckout: '60 ₽/км',
        pass: '1650 ₽ /2100 ₽ /2600 ₽',
        ndsPass: '1900 ₽/2500 ₽/3000 ₽'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 7,
          price: '18000 ₽',
          ndsPrice: '20800 ₽',
          advPrice: '<span class="d-block">2100 ₽ за</span> <span class="d-block">доп. час</span>',
          ndsAdvPrice: '<span class="d-block">2400 ₽ за</span> <span class="d-block">доп. час</span>'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '65 ₽/км',
        ndsCheckout: '70 ₽/км',
        pass: '1700 ₽/2200 ₽/2600 ₽',
        ndsPass: '1800 ₽/2400 ₽/3000 ₽'
      }]
    }]
  },{
    title: 'Рефрижератор',
    items: [{
      title: 'все авто',
      items: []
    },{
      title: 'до 1,5т',
      items: [
      {
        image: '/assets/images/tariff/tender-wagon/auto-park-10.svg',
        alt: 'Паллета 4',
        title: '<span class="d-block">4 паллета</span> <span class="d-block">3 х 1.8 х 1.8м.</span>',
        business: {
          hour: 5,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      },{
        image: '/assets/images/tariff/tender-wagon/auto-park-11.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">6 паллета</span> <span class="d-block">4 х 1.8 х 1.8м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 3т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/3t.svg',
        alt: 'Паллета 8',
        title: '<span class="d-block">8 паллета</span> <span class="d-block">4 х 2.1 х 2.1м.</span>',
        business: {
          hour: 6,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: 9,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }
    ]
    },{
      title: 'до 5т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/5t.svg',
        alt: 'Паллета 14',
        title: '<span class="d-block">14 паллета</span> <span class="d-block">6 х 2.4 х 2.3м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 10т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/10t.svg',
        alt: 'Паллета 17',
        title: '<span class="d-block">17 паллета</span> <span class="d-block">7 х 2.4 х 2.4м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    },{
      title: 'до 20т.',
      items: [{
        image: '/assets/images/tariff/tender-wagon/20t.svg',
        alt: 'Паллета 33',
        title: '<span class="d-block">33 паллета</span> <span class="d-block">13.6 х 2.45х 2.45м.</span>',
        business: {
          hour: 8,
          price: 'Под запрос',
          ndsPrice: 'Под запрос',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        businessPlus: {
          hour: '-',
          price: '-',
          ndsPrice: '-',
          advPrice: '-',
          ndsAdvPrice: '-'
        },
        checkout: '-',
        ndsCheckout: '-',
        pass: '-',
        ndsPass: '-'
      }]
    }]
  }]

  description = 'Заказать фуру до 20 тонн для перевозки и доставки ваших грузов вы можете в транспортной компании «Акро». Мы являемся одной из лучших организаций в сегменте перевозок в Москве и других крупных центрах России с опытом успешной деятельности более 5 лет. Наш автопарк включает в себя множество грузовых авто для доставки с разными характеристиками и типами кузова, в том числе фуры, газели, спецтехника и т.д., что позволяет решать разнообразные логистические задачи. Если вы планируете переезд, перевозку негабаритных вещей, бытовой техники, контейнеров, пиломатериалов из бытовок, металлопроката труб, больших партий товаров - обращайтесь к нам! Выгодные тарифы на грузоперевозки, широкий спектр услуг и индивидуальный подход позволяет не только проработать оптимальный путь, но и сэкономить ваши денежные ресурсы при доставке.'

  blockWithItems = {
    title: 'Преимущества:',
    description: 'Логисты ТК «АКРО» всегда рады проконсультировать и помочь подобрать подходящий грузовой транспорт для аренды по выгодной цене в Москве и других регионах. Если вы хотите заранее обсудить все детали грузоперевозки и составить точный заказ, позвоните нам или воспользуйтесь функцией обратного звонка от компании, оставив Ваш номер — мы свяжемся с вами в течение трех минут и поможем сделать расчет грузоперевозок онлайн-калькулятором. <br><br> Каждый наш заказчик убедился в точности и пунктуальности доставки, а также в 100% безопасности содержимого при транспортировке по Москве и Московской области, чем не могут похвастаться частники. Наши грузовые машины всегда находятся в исправном состоянии и готовы быстро перевозить грузы 20 тонн каждый день, наладив ваш грузооборот. Фурой всегда управляет и осуществляет перевозку опытный, проверенный человек. Доставим продукцию качественно по кратчайшему маршруту недорого. <br><br> Своим заказчикам мы предоставляем:',
    class: 'col-12 col-md-6 col-lg-3',
    items: [{
      image: 'assets/images/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons-1.svg',
      alt: 'Рефрижератор',
      title: '<b>Рефрижераторные</b> перевозки 20 тонн',
      description: 'Рефрижератор 20 тонн обеспечивает доставку продуктов питания или товаров с ограниченным сроком годности и требующих определенного температурного режима. Для поддержания регламентируемых требований перевозки на склады или в торговые сети транспортное средство должно быть оснащено специальным холодильным оборудованием (изотермическим фургоном), отвечающим определенным требованиям: t внутри грузового отсека от -20° до +12° с простой регулировкой. Отсек закрытый, и груз зафиксирован, даже если фура не будет полностью наполнена продуктами.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons-2.svg',
      alt: 'Тент',
      title: 'Тентованные перевозки',
      description: 'Большегрузный кузов 20 тонн тент обеспечит перевозку грузов, не требующих поддержания определенной температуры. Он водонепроницаемый, поэтому защитит от атмосферных явлений (влага, пыль, грязь, солнечные лучи и т.д.). Для осуществления погрузки и выгрузки товара он легко снимается. Это могут быть строительные материалы различной длины, вещи и т.п.',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons-3.svg',
      alt: 'Борт',
      title: 'Бортовые грузовики',
      description: 'Данный автотранспорт перевезет на своей платформе самые разные виды груза, которые не требует специальных требований транспортировки, например, стройматериалы, оборудование, металлоконструкций, перевозка мебели, арматуры и иных неприхотливых грузов. Такие авто подходят для перевозки крупногабаритного оборудования или стройматериалов в Москве и других городах России',
      class: 'p-20-xs bg-purple border-grey3'
    },{
      image: 'assets/images/trucking-up-to-twenty-tons/trucking-up-to-twenty-tons-4.svg',
      alt: 'Изотермические',
      title: 'Изотермические автомобильные грузоперевозки',
      description: 'Аренда изотермы нужна для перевозки грузов до 20 кубов с неограниченным сроком годности, не требующих хранения в особых условиях. Фура оснащена гидробортом и функцией удержания температуры, которая была на момент погрузки.',
      class: 'p-20-xs bg-purple border-grey3'
    }]
  }

  blockWithItems2 = {
    title: 'Преимущества работы с нами',
    description: 'Мы в процессе своей деятельности зарекомендовали себя с положительной стороны. Мы гарантируем:',
    class: 'col-12 col-md-6 col-lg-4',
    items: [{
      image: '',
      title: '',
      description: 'Индивидуальный подход к цене и скидки постоянным клиентам',
      class: 'px-0-xs'
    },{
      image: '',
      title: '',
      description: 'Персонального менеджера, который всегда ответит на все поставленные вопросы о грузоперевозке и оформит все сопроводительные документы (в том числе необходимые пропуска для перевозки грузов в ттк и ск)',
      class: 'px-0-xs'
    },{
      image: '',
      title: '',
      description: 'Удобный личный кабинет, в котором вы легко можете создавать новые заказы на доставку и отслеживать текущие. Также в нем есть калькулятор, которым можно воспользоваться для расчета стоимости перевозки за км.',
      class: 'px-0-xs'
    },{
      image: '',
      title: '',
      description: 'Большое разнообразие транспортных средств: грузоподъемность может составлять 500 кг, 1-3 тонны, 10-20 тонн. Вы можете выбрать тент -фургон, рефрижератор, борт или манипулятор, а если вы не знаете, какую машину заказать, наши специалисты предоставят консультацию.',
      class: 'px-0-xs'
    }]
  }

  tags = {
    title: 'Автомобильные грузоперевозки',
    class: '',
    items: [{
      class: '',
      title: '',
      children: [{
        title: 'Перевозка грузов',
        link: '/gruzoperevozki-po-moskve-i-mo'
      },{
        title: 'Грузоперевозки по России',
        link: '/perevozki-po-rossii'
      },{
        title: 'Грузоперевозки транспортом',
        link: '/avtopark'
      },{
        title: 'Грузоперевозки на автомобиле Газель',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Перевозка продуктов питания',
        link: '/avtopark/refrizheratornye-perevozki'
      },{
        title: 'Грузовые перевозки',
        link: '/'
      },{
        title: 'Междугородние перевозки',
        link: '/perevozki-po-rossii'
      },{
        title: 'Грузоперевозки 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn'
      },{
        title: 'Оптимальный транспорт для грузоперевозки',
        link: '/avtopark'
      },{
        title: 'Новый автопарки для перевозки',
        link: '/avtopark'
      },{
        title: 'Помощь грузчиков в погрузке и разгрузке',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Дешевая Перевозка 20 тонн',
        link: '/avtopark/gruzoperevozki-20tonn'
      },{
        title: 'Грузоперевозки в пределах мкад',
        link: '/'
      },{
        title: 'Грузоперевозки крупных грузов',
        link: '/'
      },{
        title: 'Грузоперевозки различной продукции',
        link: '/perevozki-po-rossii/'
      },{
        title: 'Перевозка груза малого веса',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Грузоперевозки 500 кг',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Доставка на дальние расстояния',
        link: '/perevozki-po-rossii'
      },{
        title: 'Перевозка груза любой ширины',
        link: 'avtopark'
      },{
        title: 'Перевозка фургоном',
        link: 'avtopark'
      },{
        title: 'Перевозка автотранспортом',
        link: '/'
      },{
        title: 'Перевозка для каждой задачи',
        link: 'avtopark'
      },{
        title: 'Квартирный переезд',
        link: '/gruzoperevozki-dlya-fiz-lic'
      },{
        title: 'Поставка небольших сборных партий',
        link: '/avtopark/gruzoperevozki-3tonny'
      },{
        title: 'Перевозка сыпучих грузов 5 тонн',
        link: '/avtopark/gruzoperevozki-5tonn'
      }]
    }]
  };

  faq = [{
    title: "Как узнать стоимость перевозки фурой по Москве, МО или в других регионах?",
    description: "Цена на перевозку зависит от особенностей груза, а также расстояния от точки отправления до пункта в км. Для того, чтобы узнать, сколько будет стоить аренда фуры, мы предлагаем вам <br><br> - оставить онлайн запрос в форме обратной связи<br><br> - оформить грузоперевозку через сайт с комментарием  “запрос стоимости” <br><br> - позвонить нам по номеру 8 (499) 350-90-02 <br><br> После поступления сообщения мы свяжемся с вами в течение двух минут. Он  расскажет про тарифы, озвучит стоимость  с учетом всех нюансов, а также составит индивидуальное коммерческое предложение. Обязательно предоставьте нашему специалисту всю информацию о грузе, который требуется перевезти (его вес в кг, габариты), адрес забора груза и место назначения. С нашими тарифами вы можете ознакомиться в соответствующем разделе сайта (важно: транспортировка за пределы МКАД тарифицируется отдельно)."
  }, {
    title: "Как проходит оплата?",
    description: "Стоимость часа фуры - 1 800 руб, при этом минимальная сумма - 14 400 руб (минимум 8 часов плюс 1 час - подача). Выберите удобный для вас способ расчета перевозки: постоплату, которая производится на наш расчетный счет после оказания грузоперевозки, или предоплату (в этом случае будет действовать акция, и стоимость перевозки будет ниже). Также у вас есть возможность получить скидку на первый заказ."
  }, {
    title: "Даете ли вы гарантию на сохранность груза?",
    description: "Да, мы на 100% уверены в сохранности вашей продукции и бесплатно сделаем страхование."
  }, {
    title: "Как заказать грузоперевозки фурой?",
    description: "Для найма авто вам доступны несколько вариантов на выбор: <br><br> оформите заявку на звонок в форме обратной связи (мы свяжемся с вами в за 2 минуты); <br>звоните нам по телефону 8 (499) 350-90-02; <br> пишите на почту info@tk-akro.ru <br><br> После уточнения всей информации, мы подбираем для вас технику для грузоперевозки и делает расчет стоимости. Затем заключаем с вами договор, и в назначенное время проводится перевозка."
  }, {
    title: "Предоставляете ли вы дополнительные услуги грузчиков?",
    description: "Да, если вам необходима помощь при погрузке/разгрузке, помимо доставки, вы можете воспользоваться этой услугой. Цена варьируется в зависимости от того, сколько весит ваш груз и его габаритов. В зависимости от этих параметров погрузка и разгрузка может быть проведена водителем либо помогут грузчики."
  }, {
    title: "Каким образом вы возвращаете оригиналы документации?",
    description: "После выполнения услуги грузоперевозки два раза в месяц мы доставляем оригиналы к вам в офис по адресу, указанному при регистрации. Также при необходимости можем отправить их почтой."
  }, {
    title: "Где территориально вы находитесь?",
    description: "Наш офис расположен по адресу: г. Москва, ул.Щёлковское ш., д. 2а. Наши контакты: телефон 8 (499) 350-90-02, электронная почта info@tk-akro.ru, на которую вы можете писать круглосуточно."
  }, {
    title: "Где можно получить информацию о том, какие вакансии открыты в вашей компании?",
    description: "Все открытые вакансии вы можете посмотреть на сайте hh.ru"
  }, {
    title: "Как зарегистрироваться?",
    description: "Главная страница сайта <a href='https://tk-akro.ru/'>https://tk-akro.ru/</a> содержит ссылку «Личный кабинет», нажимая на которую, вы можете перейти в форму. Вы заполняете обязательные поля (имя, контактные данные) и нажимаете кнопку «зарегистрироваться». После этого вы можете войти в сервис и вам будут доступны все его удобные опции: электронный документооборот, вы можете бесплатно отследить груз онлайн и рассчитать стоимость калькулятором. Обратите внимание, что заполняя онлайн -форму, вы принимаете нашу политику конфиденциальности и даете согласие на обработку ваших данных. Также для Вас есть полезная информация о мире грузоперевозок, включая актуальные новости."
  }, {
    title: "Осуществляете ли вы перевозки в Казахстан и другие страны СНГ?",
    description: "Мы перевозим грузы автотранспортом разной вместимости в любой город России. Мы охватываем следующие направления: Москва, Санкт-Петербург, Калининград, Краснодар, Иркутск, Ростов-на-дону, новый Уренгой, Крым, Челябинск, Омск, Сочи, Мурманск, Астрахань, Липецк, Кемерово, Оренбург, Екатеринбург, Ижевск, Хабаровск, Казань, Новосибирск, Тюмень, Воронеж, Сургут, Барнаул и т.д. Международные рейсы мы не проводим. <br><br> С ТК «Акро» вы можете быть уверены, что доставка крупногабаритных грузов будет осуществлена быстро и аккуратно. Нас выбирают владельцы малых и средних бизнесов из таких мегаполисов как Москва, Санкт-Петербург и других регионов России. Мы предоставляем отличный сервис и качество нашей деятельности, о чем свидетельствуют отзывы наших довольных заказчиков!"
  }]

  constructor(private meta: Meta, private titleService:Title) {
    this.meta.addTag({ property: 'og:title', content: 'Грузоперевозки 20 тонн Москва цена перевозки фурой снижена' });
    this.meta.addTag({ property: 'og:description', content: '✅ Грузоперевозки 20 тонн от 1340 руб ✅ Страхование вещей и грузов ✅ Подача автомобиля в день обращения ✅ Звоните +7 (499) 350-90-02' });
    this.meta.addTag({ name: 'description', content: '✅ Грузоперевозки 20 тонн от 1340 руб ✅ Страхование вещей и грузов ✅ Подача автомобиля в день обращения ✅ Звоните +7 (499) 350-90-02' });
    this.titleService.setTitle('Грузоперевозки 20 тонн Москва цена перевозки фурой снижена');
  }
}
